import React, { useEffect, useState } from "react";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";

const AddAccount = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [userLoader, setUserLoader] = useState(false);
    const [teamData, setTeamData] = useState([]);
    // const [resellerData, setResellerData] = useState([]);
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'team',
        email: '',
        name: '',
        password: '',
    });

    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const fetchClientTeam = () => {
        setUserLoader(true);
        axios({
            method: "POST",
            url: `${baseURL}fetch-clients`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setUserLoader(false);
                setTeamData(res.data.data.team_members)
                setClientData(res.data.data.clients)
                // setResellerData(res.data.data.resellers)
            }
        }).catch(error => {

        })
    }

    const addClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}create-client`,
            data: userDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchClientTeam()
                setUserDetails({
                    ...userDetails,
                    type: 'team_member',
                    email: '',
                    name: '',
                    password: ''
                })
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (userDetails.password && pattern.test(userDetails.password)) {
            setLoader(true);
            addClientTeam();
            setPasswordMsg({ ...passwordMsg, validation: false, msg: "" })
        } else {
            setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
        }
    }

    useEffect(() => {
        fetchClientTeam();
    }, []);

    return (
        <>

            <div className="profileBlock-wrap">
                <h2 className="infoTitle">Create Account</h2>
                <div className="profileBlock mt-0">

                    <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted">Account Type</label>
                                    <select className="form-control" name="type" onChange={(e) => onInputChange(e)}>
                                        <option style={{ backgroundColor: "#000" }} value="team">Team Member</option>
                                        <option style={{ backgroundColor: "#000" }} value="client">Client Account</option>
                                        {/* <option style={{ backgroundColor: "#000" }} value="reseller">Reseller Account</option> */}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt form-group-Mobile mt-0">
                                    <label className="form-text text-muted">
                                        {/* <span tooltip="Enter an account name for your client or team member account." tooltip-position="right">Account Name</span> */}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Name"
                                        required
                                        name="name"
                                        value={userDetails.name}
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">
                                        {/* <span tooltip="Provide the email address as per your client and team members’ preferences." tooltip-position="right">Account Email</span> */}
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={userDetails.email}
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                <label className="form-text text-muted">
                                    <span tooltip="Provide a strong password for your client and team members’ account security." tooltip-position="right">Account Password</span>
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="********"
                                        required
                                        name="password"
                                        value={userDetails.password}
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                                <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="fbtn btn-block demoLink" disabled={loader}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                            </button>
                        </div>

                    </form>

                </div>


                <ManageClient users={clientData} userLoader={userLoader} fetchClientTeam={fetchClientTeam} type="Client" />
                <ManageTeam users={teamData} userLoader={userLoader} fetchClientTeam={fetchClientTeam} type="Team Member" />
                {/* <ManageTeam users={resellerData} fetchClientTeam={fetchClientTeam} type="Reseller" /> */}
            </div>

        </>
    )
}

export default AddAccount;